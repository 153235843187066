import Swiper from 'swiper/bundle';
import 'swiper/css/bundle';

import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);

import SplitType from 'split-type';

jQuery(document).ready(function($) {
	var windowWidth = $(window).width()

/* ====================== Force Scroll to be top on homepage ======================  */
	if($('body').hasClass('home')) {
		window.onbeforeunload = function () {
		  window.scrollTo(0, 0);
		};
	}
/* ====================== Text Anim ======================  */
	const animSelector = 'h1:not(.no-anim),h2:not(.no-anim),h3:not(.no-anim),h4:not(.no-anim), h5:not(.no-anim),.subheading:not(.no-anim),.body-xl:not(.no-anim), .body-large:not(.no-anim), .inner-masthead p:not(.no-anim), .p-anim';
	const headingMask = new SplitType(animSelector,
	{ types: 'lines,words' })
	headingMask.lines

	const carouselContent = new SplitType('.latest-news h4, .latest-news p, .testimonial-carousel p, .related-projects h3, .related-projects p',
	{ types: 'lines,words' })
	carouselContent.lines

	const loader = new SplitType('.loader h1',
	{ types: 'lines,words,char' })
	loader.lines

	const homepageMasthead = new SplitType('.homepage-masthead p',
	{ types: 'lines,words' })
	homepageMasthead.lines


	/* ====================== Loader Animation ======================  */
	document.querySelectorAll('.loader h1').forEach(function (loaderHeading) {
		var duration = .8;
		var wordStagger = 1;
		var charStagger = 0.1;
		const tl = gsap.timeline();

		tl.to(loaderHeading.querySelectorAll(".word"), {
			opacity: 1,
			stagger: {
	  			each: wordStagger,
	  			onStart: function () {
					gsap.to(this.targets()[0].querySelectorAll(".char"), {
						y: 0,
						rotate: 0,
						ease: "power4.out",
						duration: duration,
					  stagger: charStagger,
				  });
	 			 },
  			},
		})
		.to('.loader-bg', {
			y: '-100%',
			duration: 1.5,
			ease: "power2.inOut",
		}, "+=1")
		.to('.loader h1', {
			color: '#FFF',
			duration: .5,
			ease: "power2.inOut",
		}, "-=1.1")
		.to('body', {
			overflow: 'visible',
			duration: 1.5,
			ease: "power2.inOut",
		})
	});

	/* ====================== Global Text Animation ======================  */
	document.querySelectorAll(animSelector).forEach(function(heading) {
		heading.style.opacity = 1;
		var duration = 1.4;
		var stagger = 0.1;
		gsap.to(heading.querySelectorAll(".line"), {
			scrollTrigger: {
				trigger: heading,
				start: 'top 95%',
				markers: false,
			},
		stagger: {
			each: stagger,
				onStart: function() {
					gsap.to(this.targets()[0].querySelectorAll(".word"), {
						y: 0,
						rotate: 0,
						opacity: 1,
						ease: "power4.out",
						duration: duration,
					});
				},
			},
		});
	});

  /* ====================== Image Animation ======================  */
	document.querySelectorAll('.image-anim:not(.no-anim) img').forEach(function(animimage) {
		gsap.to(animimage, {
			scrollTrigger: {
				trigger: animimage,
				start: 'top 80%',
				markers: false,
			},
			y: 0,
			scale: 1,
			rotate: 0,
			opacity: 1,
			ease: "power4.out",
			duration: 1.4,
			markers: false,
			stagger: {
				each: 0.5,
				onStart: function() {
					gsap.to(this.targets(), {
						y: 0,
						scale: 1,
						rotate: 0,
						ease: "power4.out",
						duration: 3
					});
				}
			}
		});
	});

	document.querySelectorAll('.image-anim:not(.no-anim)').forEach(function(animImageContainer) {
		gsap.to(animImageContainer, {
			scrollTrigger: {
				trigger: animImageContainer,
				start: 'top 80%',
				markers: false,
			},
			y: 0,
			scale: 1,
			rotate: 0,
			opacity: 1,
			ease: "power4.out",
			duration: 1.4,
			markers: false,
			y: 0,
			scale: 1,
			opacity: 1,
			ease: "power4.out",
			duration: 3,
			stagger: {
				each: 0.5,
			}
		});
	});

/* ====================== SIZING ELEMENTS ======================  */
	function setElementWidthToScreenRight(element) {
		if($(element).length) {
			var elementOffsetLeft = document.querySelector(element).getBoundingClientRect().left
			var distanceToRightEdge = windowWidth - elementOffsetLeft;
			distanceToRightEdge = distanceToRightEdge;
			$(element).width(distanceToRightEdge);
		}
	}

	setElementWidthToScreenRight('.tab-content .dividing-line');

/* ====================== MOBILE NAV ======================  */
	$('.menu-toggle').on("click", function() {
		$(this).toggleClass('active');
		$('header nav').toggleClass('open');
		$('header').toggleClass('open');
		$('body').toggleClass('no-scroll');
	})

	if(windowWidth < 1280) {
		$('#menu-main-menu .menu-item-has-children').on("click", function() {
		   $(this).toggleClass('open');
		   $(this).find('.sub-menu').slideToggle();
		})

		$('.sub-menu a').on("click", function(event) {
			event.stopPropagation();
		});
	}

/* ====================== Lets Talk CTA ======================  */
	var prevScrollpos = window.pageYOffset;
	var currentScrollPos = window.pageYOffset;

	if(currentScrollPos > 120) {
		$(".sticky-cta").addClass("scrolled");
	}

	window.onscroll = function() {
		var currentScrollPos = window.pageYOffset;

		if(currentScrollPos > 120) {
			$(".sticky-cta").addClass("scrolled");
		} else {
			$(".sticky-cta").removeClass("scrolled");
		}

		prevScrollpos = currentScrollPos;
	}

/* ====================== Featured Projects Carousel ======================  */
	const featured_projects = new Swiper('.featured-projects .swiper', {
		slidesPerView: 1.4,
		speed: 600,
		loop: false,
		watchSlidesProgress: true,
		spaceBetween: 10,
		breakpoints: {
			768: {
				slidesPerView: 2.2,
				spaceBetween: 30,
			},
			1280: {
				spaceBetween: 20,
				slidesPerView: 2.5,
			},
		},
		pagination: {
			el: ".featured-projects .pagination",
			clickable: true
		},
	});

/* ====================== Latest News Carousel ======================  */
	const latest_news_slider = new Swiper('.latest-news .swiper', {
		slidesPerView: 1.1,
		speed: 1200,
		loop: true,
		watchSlidesProgress: true,
		spaceBetween: 20,
		navigation: {
			nextEl: ".latest-news .swiper-button-next",
			prevEl: ".latest-news .swiper-button-prev",
		},
		pagination: {
			el: ".latest-news .pagination",
			clickable: true
		},
		breakpoints: {
			768: {
				slidesPerView: 1,
				spaceBetween: 30,
			}
		},
	});

/* ====================== Testimonials Carousel ======================  */
	const testimonials_carousel = new Swiper('.testimonial-carousel .swiper', {
		slidesPerView: 1,
		speed: 600,
		loop: true,
		rewind: false,
		watchSlidesProgress: true,
		effect: "fade",
		fadeEffect: { crossFade: true },
		navigation: {
			nextEl: ".testimonial-carousel .swiper-button-next",
			prevEl: ".testimonial-carousel .swiper-button-prev",
		},
		spaceBetween: 50,
	});
    testimonials_carousel.on('realIndexChange', function(swiper) {
    	const realIndex = swiper.realIndex;

    	$('.testimonial-carousel .background-image-container').removeClass('active');
    	$('.testimonial-carousel .background-image-container[testimonial-index= '+ realIndex +']').addClass('active');
    });



/* ====================== Team Carousel ======================  */
	const team_carousel = new Swiper('.team .swiper', {
		slidesPerView: 1.5,
		speed: 600,
		loop: false,
		watchSlidesProgress: true,
		spaceBetween: 20,
		breakpoints: {
			1280: {
				slidesPerView: 1.5,
			},
		},
		pagination: {
			el: ".team .pagination",
			clickable: true
		},
	});
/* ====================== Related Projects Carousel ======================  */
	const related_projects_carousel = new Swiper('.related-projects .swiper', {
		slidesPerView: 1,
		speed: 1200,
		loop: true,
		watchSlidesProgress: true,
		spaceBetween: 20,
		navigation: {
			nextEl: ".related-projects .swiper-button-next",
			prevEl: ".related-projects .swiper-button-prev",
		},
	});

/* ====================== Gallery Carousel ======================  */
	const gallery_carousel = new Swiper('.gallery-carousel .swiper', {
		slidesPerView: 1.1,
		speed: 600,
		loop: false,
		watchSlidesProgress: true,
		spaceBetween: 10,
		breakpoints: {
			768: {
				slidesPerView: 1.35,
				spaceBetween: 20,
			},
		},
		pagination: {
			el: ".gallery-carousel .pagination",
			clickable: true
		},
	});

/* ====================== Submit Gravity Form ======================  */
	$(document).on('gform_post_render', function(){
		 $('.gform_fields .btn').on('click', function() {
			$(this).parents('.gform_wrapper').find('input[type=submit]').click();
		});
	});

/* ====================== Tabbed Content ======================  */
	$('.tabbed-content').on( 'click', '.tab-content:not(.active)', function( e ){
		var index = $(this).attr('index');
		$('.tabbed-content .tab-content.active').removeClass('active');
		$('.tabbed-content .tab-image.active').removeClass('active');
		$('.tabbed-content .tab-image[index='+ index +']').addClass('active');
		$(this).addClass('active');
	});

/* ====================== Values ======================  */
	$('.values').on( 'click', '.value:not(.active)', function( e ){
		$('.values .value.active').removeClass('active');
		$(this).addClass('active');
	});

/* ====================== GSAP ======================  */
	let animate = gsap.utils.toArray('.animate')
	animate.forEach((item, index) => {
		let tl = gsap.timeline({
			scrollTrigger: {
				trigger: item,
				once: true,
				start:"0 80%",
				toggleActions:"restart none none reset",
				end: "500",
				markers: false,
				toggleClass: 'animateelement'
			}
		});
	});


	/* ====================== Pinned Section ======================  */
	if(windowWidth > 767) {
		const contentElements = gsap.utils.toArray(".content-and-image-columns .content-row");
		if(contentElements.length) {
			const images = gsap.utils.toArray(".content-and-image-columns .image-container");
			const height = $('.content-and-image-columns').height;
			const tl = gsap.timeline({
			  scrollTrigger: {
				trigger: ".content-and-image-columns",
				start: "top top",
				end: 'bottom bottom',
				pin: true,
				scrub: 2,
				markers: false,
				pinSpacing: false
			  }
			});

			images.forEach((img, i) => {
				if (images[i + 1]) {
					tl.to(images[i + 1], {
							y: 0,
							ease: "none"
						})
					  .to(contentElements, {
						yPercent: -(100 * (i + 1)),
						ease: "none"
					  },"<");
				  }
			});
		}
	}

/* ====================== Full Size Media Scaling ======================  */
	if(windowWidth > 1023) {
		let media = gsap.utils.toArray('.full-width-media img');
		media.forEach((item, index) => {
			let tl = gsap.timeline({
			scrollTrigger: {
				scrub: 2,
				trigger: item,
				start:"-200 80%",
				toggleActions:"restart none none reset",
				end: "50% 70%",
				markers: false
			}
			});
			tl.to(item, {
				width: '100%',
				borderRadius: 0,
			});
		});
	}

/* ====================== Dividing Line Scaling ======================  */
	let dividing_line = gsap.utils.toArray('.dividing-line:not(.toggle-anim):not(.no-anim)');
	dividing_line.forEach((item, index) => {
		let tl = gsap.timeline({
		scrollTrigger: {
			scrub: 2,
			trigger: item,
			start:"0 80%",
			toggleActions:"restart none none reset",
			end: "+=200",
			markers: false
		}
		});
		tl.to(item, {
			scaleX: '100%',
		});
	});

/* ====================== Smooth Scroll - Doesn't conflict with ajax smooth scroll ======================  */
	// Add smooth scrolling to all links
	$("a").on('click', function(event) {

	// Make sure this.hash has a value before overriding default behavior
	if (this.hash !== "") {
	  // Prevent default anchor click behavior
	  event.preventDefault();

	  // Store hash
	  var hash = this.hash;

	  // Using jQuery's animate() method to add smooth page scroll
	  // The optional number (800) specifies the number of milliseconds it takes to scroll to the specified area
	  $('html, body').animate({
		scrollTop: $(hash).offset().top
	  }, 500, function(){

		// Add hash (#) to URL when done scrolling (default click behavior)
		window.location.hash = hash;
	  });
	} // End if
	});
});
